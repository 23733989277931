<script setup>
import { onBeforeUnmount, onMounted, ref } from 'vue';

const props = defineProps({
  uId: {
    type: Number,
  },
  closeTime: {
    type: Number,
    default: 4000,
  },
  showCloseBtn: {
    type: Boolean,
    default: false,
  },
  message: {
    type: String,
    default: '',
  },
  whiteMode: {
    type: Boolean,
    default: false,
  },
  actionText: {
    type: String,
    default: '',
  },
  action: {
    type: Function,
    default: () => {
    },
  },
});

const emits = defineEmits(['click:close', 'click:action']);
const timeout = ref(null);

const onClickClose = () => {
  emits('click:close', props.uId);
};

const onClickAction = () => {
  if (props.action) {
    props.action();
    emits('click:action', props.uId);
  }
};
onMounted(() => {
  timeout.value = setTimeout(() => {
    emits('click:close', props.uId);
  }, props.closeTime);
});

onBeforeUnmount(() => {
  if (timeout.value) {
    clearTimeout(timeout.value);
    timeout.value = null;
  }
});
</script>
<template>
  <aside class="b-toast" :class="{'white-mode': whiteMode}">
    <p class="msg" v-dompurify-html="message"></p>
    <!-- close -->
    <button
      v-if="showCloseBtn"
      type="button"
      class="btn-close"
      @click="onClickClose"
      aria-label="토스트 닫기"
    ></button>
    <!-- action -->
    <component
      :is="action ? 'button' : 'p'"
      v-if="actionText"
      class="action-text"
      type="button"
      @click="onClickAction"
    >
      {{ actionText }}
    </component>
  </aside>
</template>

<script>
/**
 * Toast 컴포넌트
 *
 * @vue-prop {Number} uId - toast id
 * @vue-prop {String} message - toast 메세지
 * @vue-prop {Number} closeTime - 자동 닫기 시간(ms 단위)
 * @vue-prop {Number} showCloseBtn - 닫기 버튼 보여주기
 * @vue-prop {Number} actionText - action 텍스트
 *
 * @vue-event click:close - close 이벤트
 * @vue-event click:action - action 이벤트
 *
 *
 *
 * @useComponent
 * const toast = inject('toast');
 * toast.open({
      message: '안녕하세요',
      closeTime: 10000,
      actionText: '받은쿠폰보기',
      action: () => {
        console.log('action!!!');
      },
    });
 *
 *
 *toast.open({
    message: '안녕하세요.',
    closeTime: 10000,
    showCloseBtn: true,
  });
 *
 */
export default {};
</script>
