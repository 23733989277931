/**
  *@desc device height 100% setting
*/
import { onBeforeUnmount, onMounted } from 'vue';

export const useMaxHeight = () => {
  const setViewportHeight = () => {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  };

  onMounted(() => {
    window.addEventListener('resize', setViewportHeight);
    window.addEventListener('load', setViewportHeight);
    setViewportHeight();
  });
  onBeforeUnmount(() => {
    window.removeEventListener('resize', setViewportHeight);
    window.removeEventListener('load', setViewportHeight);
  });
};
