/**
 * @desc DATE TIME FORMAT 유형
 * @memberof constants
 * @readonly
 * @const DATE_TIME_FORMAT_TYPE
 * @property {String} YYYY - YYYY
 * @property {String} DASH_YYYYMM - YYYY-MM
 * @property {String} DASH_YYYYMMDD - YYYY-MM-DD
 * @property {String} DASH_YYYYMMDDHH - YYYY-MM-DD HH
 * @property {String} DASH_YYYYMMDDHHMM - YYYY-MM-DD HH:mm
 * @property {String} DASH_YYYYMMDDHHMMSS - YYYY-MM-DD HH:mm:ss
 * @property {String} DOT_YYYYMM - YYYY.MM
 * @property {String} DOT_YYYYMMDD - YYYY.MM.DD
 * @property {String} DOT_YYYYMMDDHH - YYYY.MM.DD HH
 * @property {String} DOT_YYYYMMDDHHMM - YYYY.MM.DD HH:mm
 * @property {String} DOT_YYYYMMDDHHMMSS - YYYY.MM.DD HH:mm:ss
 * @property {String} HHMM - HH:mm
 * @property {String} HHMMSS - HH:mm:ss
 * @property {String} MMSS - mm:ss
 * @property {String} SS - ss
 */
export const DATE_TIME_FORMAT_TYPE = Object.freeze({
  YYYY: 'YYYY',
  DASH_YYYYMM: 'YYYY-MM',
  DASH_YYYYMMDD: 'YYYY-MM-DD',
  DASH_YYYYMMDDHH: 'YYYY-MM-DD HH',
  DASH_YYYYMMDDHHMM: 'YYYY-MM-DD HH:mm',
  DASH_YYYYMMDDHHMMSS: 'YYYY-MM-DD HH:mm:ss',
  DOT_YYYYMM: 'YYYY.MM',
  DOT_YYYYMMDD: 'YYYY.MM.DD',
  DOT_YYYYMMDDHH: 'YYYY.MM.DD HH',
  DOT_YYYYMMDDHHMM: 'YYYY.MM.DD HH:mm',
  DOT_YYYYMMDDHHMMSS: 'YYYY.MM.DD HH:mm:ss',
  HHMM: 'HH:mm',
  HHMMSS: 'HH:mm:ss',
  MMSS: 'mm:ss',
  SS: 'ss',
});
