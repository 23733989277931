/**
 * Error 유형 및 처리를 위한 상수
 * @memberof constants
 * @readonly
 * @const ERROR_TYPE
 * @property {String} BLOCKED_RESPONSE
 * @property {String} ACCESS_DENIED
 * @property {String} BUSINESS_ERROR
 * @property {String} SYSTEM_ERROR
 * @property {String} NETWORK_ERROR
 * @property {String} UNAUTHORIZED
 */
export const ERROR_TYPE = Object.freeze({
  BLOCKED_RESPONSE: 'BLOCKED_RESPONSE',
  ACCESS_DENIED: 'ACCESS_DENIED',
  BUSINESS_ERROR: 'BUSINESS_ERROR',
  SYSTEM_ERROR: 'SYSTEM_ERROR',
  NETWORK_ERROR: 'NETWORK_ERROR',
  UNAUTHORIZED: 'UNAUTHORIZED',
  FORBIDDEN: 'FORBIDDEN',
  PAGE_NOT_FOUND: 'PAGE_NOT_FOUND',
  PAGE_REQUEST_ERROR: 'PAGE_REQUEST_ERROR',
});
