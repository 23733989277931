import { generateVNode } from '@/utils';
import { createVNode, ref, nextTick } from 'vue';
import Confirm from './components/Confirm.vue';

const ConfirmPlugin = {
  install(app) {
    const instance = ref(null);

    instance.value = generateVNode(app, createVNode(Confirm, {}), 'teleport-confirm');

    const target = {
      open({
        title = '',
        message = '',
        buttons = [],
      }) {
        nextTick(() => {
          const exposed = instance.value?.component?.exposed;
          if (exposed) {
            exposed.title.value = title;
            exposed.message.value = message;
            exposed.buttons.value = buttons;
            exposed.open();
          }
        });
      },
      close() {
        const exposed = instance.value?.component?.exposed;
        if (exposed) {
          exposed.close();
        }
      },
    };

    app.provide('confirm', target);
    app.config.globalProperties.$confirm = target;
  },
};

export default ConfirmPlugin;
