<script setup>
import { Swiper, SwiperSlide } from 'swiper/vue';
import { ref, watch } from 'vue';
import Dimmed from '@/components/common/Dimmed.vue';
import { useModalStore } from '@/stores/common/modalStore';

import 'swiper/css/bundle';

defineProps({
  items: {
    type: Array,
    default: () => [],
  },
  initSlide: {
    type: Number,
    default: 0,
  },
});

const swiper = ref(null);
const isOpen = ref(false);
const modalStore = useModalStore();

const onSwiper = (swiperArg) => {
  swiper.value = swiperArg;
};

const open = () => {
  isOpen.value = true;
  modalStore.open();
  // fetch('https://fakestoreapi.com/products')
  //   .then((res) => res.json())
  //   .then((json) => console.log(json));
  // swiper.value.update
};

const close = () => {
  isOpen.value = false;
  modalStore.close();
};

// when router leave
watch(() => modalStore.isOpen, (n) => {
  if (!n && isOpen.value) {
    isOpen.value = false;
  }
});

const isScale = ref(false);
const onMousedown = () => {
  console.log('down');
  isScale.value = true;
};

const onMouseup = () => {
  console.log('up');
  isScale.value = false;
};

const onClick = () => {
  console.log('click');
};

defineExpose({
  open,
  close,
  isOpen,
});

</script>
<template>
  <Dimmed :open="isOpen" @click:dimmed="close()"/>
  <transition name="fade">
    <aside class="b-my-coupon" v-if="isOpen">
      <Swiper
        observer
        observe-parents
        observe-slide-children
        loading="lazy"
        :centeredSlides="true"
        :initial-slide="initSlide"
        slides-per-view="auto"
        @swiper="onSwiper"
      >
        <SwiperSlide
          v-for="index in 10"
          :key="index"

          :class="{scale : isScale}"
        >
          <div
            class="my-coupon-wrap"
            @mousedown="onMousedown"
            @touchstart="onMousedown"
            @touchend="onMouseup"
            @mouseup="onMouseup"
          >

          </div>
        </SwiperSlide>
      </Swiper>
    </aside>
  </transition>
</template>
