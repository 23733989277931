import { defineStore } from 'pinia';
import { ref } from 'vue';
import { useScrollLock } from '@vueuse/core';

export const useModalStore = defineStore('common/modalStore', () => {
  const isOpen = ref(false);
  const wrapper = document.querySelector('body');
  const isLocked = useScrollLock(wrapper);

  const open = () => {
    isOpen.value = true;
    isLocked.value = true;
  };

  const close = () => {
    isOpen.value = false;
    isLocked.value = false;
  };

  return {
    open,
    close,
    isOpen,
  };
});
