<script setup>
import { useSpinnerStore } from '@/stores/common/spinnerStore';
import { watch } from 'vue';
import { storeToRefs } from 'pinia';

const { isOpen } = storeToRefs(useSpinnerStore());

watch(() => isOpen, (newValue) => {
  console.log('watch ==>', newValue);
});

</script>
<template>
  <section class="spinner-wrap" v-if="isOpen">
    <div class="spinner"></div>
  </section>
</template>
