import { createVNode } from 'vue';
import { generateVNode } from '@/utils';
import { useToastStore } from '@/stores/common/toastStore';
import ToastWrap from './components/ToastWrap.vue';

const ToastPlugin = {
  install(app) {
    generateVNode(app, createVNode(ToastWrap, {}), 'teleport-toast');

    const toastStore = useToastStore();
    const target = {
      open({
        message = '',
        showCloseBtn = false,
        closeTime = 4000,
        actionText = '',
        whiteMode = false,
        action = () => {},
      }) {
        return toastStore.open({
          message,
          showCloseBtn,
          closeTime,
          actionText,
          whiteMode,
          action,
        });
      },
      close(uId) { toastStore.close(uId); },
    };

    app.provide('toast', target);
    app.config.globalProperties.$toast = target;
  },
};

export default ToastPlugin;
