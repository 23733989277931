<script setup>
import {
  defineAsyncComponent, onMounted, watch, shallowRef, ref,
  onBeforeUnmount,
  handleError,
} from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useMaxHeight } from './composables';
import LayoutEmpty from './layouts/LayoutEmpty.vue';
import { ERROR_TYPE, ROUTER_PATH } from './constants';

useMaxHeight();

const getTimestamp = () => new Date().getTime();
const routerViewKey = ref(getTimestamp());
const route = useRoute();
const updateLayout = shallowRef(LayoutEmpty); // 초기값을 LayoutWithFooter로 설정

const router = useRouter();

// 업데이트 layout
const updateLayoutComponent = () => {
  const layoutName = route.meta.layout;
  console.log(layoutName);
  if (layoutName) {
    updateLayout.value = defineAsyncComponent(() => import(`@/layouts/${layoutName}.vue`));
  } else {
    updateLayout.value = LayoutEmpty;
  }
};

/** 인터넷 연결 disconnect error */
function handleNetworkError() {
  router.push({ path: ROUTER_PATH.ERROR, query: { state: ERROR_TYPE.NETWORK_ERROR } });
  console.warn('Disconnect internet!!');
}

// eslint-disable-next-line no-unused-vars
watch(() => route.meta.layout, (n) => {
  updateLayoutComponent();
}, { immediate: true });

// hook 에 걸기
onMounted(() => {
  updateLayoutComponent();
  window.addEventListener('offline', handleNetworkError);
});

onBeforeUnmount(() => {
  window.removeEventListener('offline', handleNetworkError);
});

</script>

<template>
  <component :is="updateLayout">
    <RouterView :key="routerViewKey"/>
  </component>
  <div id="teleport"></div>
</template>
