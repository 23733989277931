import { createRouter, createWebHistory } from 'vue-router';
import { beforeEach } from '@/config/routerHandler';
import { ERROR_TYPE, ROUTER_PATH } from '@/constants';

const routes = [
  {
    path: ROUTER_PATH.HOME,
    name: 'HOME',
    component: () => import('@/views/home/Home.vue'),
    meta: {
      layout: 'LayoutWithFooter',
    },
  },
  {
    path: ROUTER_PATH.SPLASH,
    name: 'SPLASH',
    component: () => import('@/views/splash/Splash.vue'),
    meta: {
      layout: 'LayoutEmpty',
    },
  },
  {
    path: ROUTER_PATH.GROUP_HOME,
    name: 'GROUP_HOME',
    component: () => import('@/views/group/GroupHome.vue'),
    meta: {
      layout: 'LayoutEmpty',
    },
  },
  {
    path: ROUTER_PATH.GROUP_LIST,
    name: 'GROUP_LIST',
    component: () => import('@/views/group/GroupList.vue'),
    meta: {
      layout: 'LayoutEmpty',
    },
  },
  {
    path: ROUTER_PATH.BRAND_HOME,
    name: 'BRAND_HOME',
    component: () => import('@/views/brand/home/BrandHome.vue'),
    meta: {
      layout: 'LayoutEmpty',
    },
  },
  {
    path: ROUTER_PATH.BRAND_DETAIL,
    name: 'BRAND_DETAIL',
    component: () => import('@/views/brand/detail/BrandDetail.vue'),
    meta: {
      layout: 'LayoutEmpty',
    },
  },
  {
    path: ROUTER_PATH.MY_BENEFITS,
    name: 'MY_BENEFITS',
    component: () => import('@/views/my/MyBenefit.vue'),
    meta: {
      layout: 'LayoutEmptyWithPadding',
    },
  },
  {
    path: ROUTER_PATH.PREVIOUS_BENEFITS,
    name: 'PREVIOUS_BENEFITS',
    component: () => import('@/views/my/PreviousBenefit.vue'),
    meta: {
      layout: 'LayoutEmptyWithPadding',
    },
  },
  {
    path: ROUTER_PATH.USED_BENEFITS,
    name: 'USED_BENEFITS',
    component: () => import('@/views/my/NotUseBenefit.vue'),
    meta: {
      layout: 'LayoutEmpty',
    },
  },
  {
    path: ROUTER_PATH.USE_BENEFITS,
    name: 'USE_BENEFITS',
    component: () => import('@/views/my/UseBenefit.vue'),
    meta: {
      layout: 'LayoutEmpty',
    },
  },
  {
    path: ROUTER_PATH.DIRECT_USE_BENEFIT,
    name: 'DIRECT_USE_BENEFIT',
    component: () => import('@/views/my/DirectUseBenefit.vue'),
    meta: {
      layout: 'LayoutEmpty',
    },
  },
  {
    path: ROUTER_PATH.NOTICE_LIST,
    name: 'NOTICE_LIST',
    component: () => import('@/views/notice/NoticeList.vue'),
    meta: {
      layout: 'LayoutEmptyWhite',
    },
  },
  {
    path: ROUTER_PATH.NOTICE_DETAIL,
    name: 'NOTICE_DETAIL',
    component: () => import('@/views/notice/NoticeDetail.vue'),
    meta: {
      layout: 'LayoutEmptyWhite',
    },
  },
  {
    path: ROUTER_PATH.FAQ,
    name: 'FAQ',
    component: () => import('@/views/faq/FAQ.vue'),
    meta: {
      layout: 'LayoutEmptyWhite',
    },
  },
  {
    path: ROUTER_PATH.PRIVATE_POLICY,
    name: 'PRIVATE_POLICY',
    component: () => import('@/views/terms/PolicyDetail.vue'),
    meta: {
      layout: 'LayoutEmptyWhite',
    },
  },
  {
    path: ROUTER_PATH.TERMS_LIST,
    name: 'TERMS_LIST',
    component: () => import('@/views/terms/TermsList.vue'),
    meta: {
      layout: 'LayoutEmptyWhite',
    },
  },
  {
    path: ROUTER_PATH.TERMS_DETAIL,
    name: 'TERMS_DETAIL',
    component: () => import('@/views/terms/TermsDetail.vue'),
    props: (route) => ({ state: route.query.state }),
    meta: {
      layout: 'LayoutEmptyWhite',
    },
  },
  {
    path: ROUTER_PATH.KAKAO,
    name: 'KAKAO',
    component: () => import('@/views/kakao/Index.vue'),
    meta: {
      layout: 'LayoutEmpty',
    },
  },
  {
    path: ROUTER_PATH.AGREE_TERMS,
    name: 'AGREE_TERMS',
    component: () => import('@/views/terms/AgreeTerms.vue'),
    meta: {
      layout: 'LayoutEmpty',
    },
  },
  {
    path: ROUTER_PATH.AUTH,
    name: 'AUTH',
    component: () => import('@/views/auth/Auth.vue'),
    meta: {
      layout: 'LayoutEmpty',
    },
  },
  {
    path: ROUTER_PATH.AUTH_RESULT,
    name: 'AUTH_RESULT',
    component: () => import('@/views/auth/AuthResult.vue'),
    meta: {
      layout: 'LayoutEmpty',
    },
  },
  {
    path: ROUTER_PATH.ERROR,
    name: 'ERROR',
    component: () => import('@/views/error/Error.vue'),
    meta: {
      layout: 'LayoutEmpty',
    },
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: { path: ROUTER_PATH.ERROR, query: { state: ERROR_TYPE.PAGE_NOT_FOUND } },
  },
];

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
  scrollBehavior() {
    return false;
    // return { top: 0 }; // 또는 `false`로 설정하여 스크롤 동작을 제어할 수 있음
  },
});

router.beforeEach(beforeEach);

export default router;
