<script setup>

defineProps({
  open: {
    type: Boolean,
    default: false,
  },
});
const emits = defineEmits(['click:dimmed']);

const onClick = () => {
  emits('click:dimmed');
};
</script>
<template>
  <transition name="fade">
    <div
      v-if="open"
      class="b-dimmed"
      @click="onClick"
    >
    </div>
  </transition>
</template>
<script>
/**
 * A dimmed overlay component that emits a click event when clicked.
 *
 * This component is used to create a dimming effect on the background,
 * typically used in modal dialogs or popups.
 *
 * @component
 *
 * @props {boolean} open - Determines whether the dimmed overlay is visible. Defaults to false.
 *
 * @emits {Event} click:dimmed - Emitted when the dimmed area is clicked.
 *
 * @example
 * <DimmedOverlay
 *   :open="isOverlayOpen"
 *   @click:dimmed="handleDimmedClick"
 * />
 */
</script>
