// component 외부 클릭시 이벤트 발생
export default function clickOutside() {
  return {
    mounted(el, binding) {
      if (binding.value) {
        el.clickOutside = (event) => {
          if (!(el === event.target || el.contains(event.target))) {
            binding.value(event, binding.arg);
          }
        };
        document.body.addEventListener('click', el.clickOutside);
      }
    },
    unmounted(el, binding) {
      if (binding.value) {
        document.body.removeEventListener('click', el.clickOutside);
      }
    },
  };
}
