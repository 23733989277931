/**
 * @desc defined Key of Session Storage
 * @memberof constants
 * @readonly
 * @const SESSION_STORAGE_KEY
 * @property {String} SESSION_STORAGE_KEY
 */
export const SESSION_STORAGE_KEY = Object.freeze({
  API_KEY: 'apiKey', // serial,
  MTS_PROVIDER: 'mtsProvider', // mts,
  MEMBER_ID: 'memberID',
});
