import { createApp } from 'vue';

import App from './App.vue';

import registerDirectives from './directives';
import registerPlugins from './plugins';
import '@/assets/scss/service-mobile.scss';

const app = createApp(App);

// global directives 등록
registerDirectives(app);

// library / plugin 등록
registerPlugins(app);

// 필요한 경우 global component 등록

app.mount('#app');
