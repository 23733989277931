import moment from 'moment';
import { isNumeric, removeNumberComma, replaceAll } from '@/utils';
import { DATE_TIME_FORMAT_TYPE } from '@/constants';
import { mixed, string, number } from './localeKo';
import { map, every } from 'lodash-es'; // tree shaking 을 위해 반드시 필요한 함수만 호출

/**
 * yup custom method 정의
 * @namespace yupMethods
 */

/**
 * YUP 전용: 카드번호 체크 메소드
 * @memberof yupMethods
 * @method cardNum
 * @param {string} [message] - 에러 메세지
 */
function cardNum() {
  return {
    // 카드번호 체크
    schemaType: 'string',
    name: 'cardNum',
    func(message) {
      return this.test({
        name: 'cardNum',
        message: message || string.cardNum,
        test(value) {
          if (value) {
            return /^\d{4}-\d{4}-\d{4}-\d{4}$/.test(value);
          }
          return true;
        },
      });
    },
  };
}

/**
 * YUP 전용: 숫자 형태인지 체크 메소드
 * @memberof yupMethods
 * @method numeric
 * @param {string} [message] - 에러 메세지
 */
function numeric() {
  return {
    schemaType: 'string',
    name: 'numeric',
    func(message) {
      return this.test({
        name: 'numeric',
        message: message || string.numeric,
        test(value) {
          if (value) {
            return isNumeric(replaceAll(removeNumberComma(value), '-', ''));
          }

          return true;
        },
      });
    },
  };
}

/**
 * YUP 전용: comma가 포함된 integer 형식 체크 메소드
 * @memberof yupMethods
 * @method integerComma
 * @param {string} [message] - 에러 메세지
 */
function integerComma() {
  return {
    // comma 형식 integer 체크
    schemaType: 'string',
    name: 'integerComma',
    func(message) {
      return this.test({
        name: 'integerComma',
        message: message || string.integerComma,
        test(value) {
          if (value) {
            return removeNumberComma(value) % 1 === 0;
          }
          return true;
        },
      });
    },
  };
}

/**
 * YUP 전용: comma가 포함된 float 형식 체크 메소드
 * @memberof yupMethods
 * @method floatComma
 * @param {string} [message] - 에러 메세지
 */
function floatComma() {
  return {
    schemaType: 'string',
    name: 'floatComma',
    func(message) {
      return this.test({
        name: 'floatComma',
        message: message || string.floatComma,
        test(value) {
          // 실수 판단...
          if (value) {
            return removeNumberComma(value) % 1 !== 0;
          }
          return true;
        },
      });
    },
  };
}

/**
 * YUP 전용: 소수점 자리수 체크 메소드
 * @memberof yupMethods
 * @method decimalPoint
 * @param {Number} [decimalPointArg=2] - 소수점 자리수
 * @param {string} [message] - 에러 메세지
 */
function decimalPoint() {
  return {
    schemaType: 'string',
    name: 'decimalPoint',
    func(decimalPointArg, message) {
      const paramDecimalPoint = decimalPointArg || 2;
      return this.test({
        name: 'decimalPoint',
        message: message || string.decimalPoint,
        params: { decimalPoint: paramDecimalPoint }, // message쪽에 넘길 파라미터
        test(value) {
          if (value) {
            const decimalPointValue = removeNumberComma(value).split('.');
            return (
              decimalPointValue.length === 1 ||
              (decimalPointValue.length > 1 &&
                decimalPointValue[1].length <= paramDecimalPoint)
            );
          }
          return true;
        },
      });
    },
  };
}

/**
 * YUP 전용: 소수점 자리수 체크 메소드
 * @memberof yupMethods
 * @method decimalPoint
 * @param {Number} [decimalPointArg=2] - 소수점 자리수
 * @param {string} [message] - 에러 메세지
 */
function numberDecimalPoint() {
  return {
    schemaType: 'number',
    name: 'numberDecimalPoint',
    func(decimalPointArg, message) {
      const paramDecimalPoint = decimalPointArg || 2;
      return this.test({
        name: 'numberDecimalPoint',
        message: message || number.decimalPoint,
        params: { decimalPoint: paramDecimalPoint }, // message쪽에 넘길 파라미터
        test(value) {
          if (value) {
            const decimalPointValue = removeNumberComma(String(value)).split(
              '.'
            );
            return (
              decimalPointValue.length === 1 ||
              (decimalPointValue.length > 1 &&
                decimalPointValue[1].length <= paramDecimalPoint)
            );
          }
          return true;
        },
      });
    },
  };
}

/**
 * YUP 전용: IPv4 형식 체크 메소드
 * @memberof yupMethods
 * @method ip
 * @param {string} [message] - 에러 메세지
 */
function ip() {
  return {
    // ip v4 형식 맞는지 체크
    schemaType: 'string',
    name: 'ip',
    func(message) {
      return this.test({
        name: 'ip',
        message: message || string.ip,
        test(value) {
          if (value) {
            // eslint-disable-next-line max-len
            return /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(
              value
            );
          }
          return true;
        },
      });
    },
  };
}

/**
 * YUP 전용: dateTimeFormat 유효성 체크
 * @memberof yupMethods
 * @method dateTimeFormat
 * @param {string} [dateTimeFormatArg=DATE_TIME_FORMAT_TYPE.DASH_YYYYMMDD] - dateTimeFormat(DATE_TIME_FORMAT_TYPE 상수 이용)
 * @param {string} [message] - 에러 메세지
 */
function dateTimeFormat() {
  return {
    schemaType: 'string',
    name: 'dateTimeFormat',
    func(dateTimeFormatArg, message) {
      const paramDateTimeFormat =
        dateTimeFormatArg || DATE_TIME_FORMAT_TYPE.DASH_YYYYMMDD;
      return this.test({
        name: 'dateTimeFormat',
        message: message || string.dateTimeFormat,
        params: { dateTimeFormat: paramDateTimeFormat },
        test(value) {
          if (value) {
            return moment(value, paramDateTimeFormat, true).isValid();
          }
          return true;
        },
      });
    },
  };
}

/**
 * YUP 전용: dateTime이 targetDateTime 보다 이후 날짜 인지 유효성 체크
 * @memberof yupMethods
 * @method dateTimeMin
 * @param {string} [targetDateTime=오늘날짜(YYYY-MM-DD)] - 비교 대상 날짜시간(ex: 2023-04-12)
 * @param {string} [dateTimeFormatArg=DATE_TIME_FORMAT_TYPE.DASH_YYYYMMDD] - dateTimeFormat(DATE_TIME_FORMAT_TYPE 상수 이용)
 * @param {string} [message] - 에러 메세지
 */
function dateTimeMin() {
  return {
    schemaType: 'string',
    name: 'dateTimeMin',
    func(targetDateTime, dateTimeFormatArg, message) {
      const paramDateTimeFormat =
        dateTimeFormatArg || DATE_TIME_FORMAT_TYPE.DASH_YYYYMMDD;
      const paramTargetDateTime =
        targetDateTime || moment().format(paramDateTimeFormat);
      return this.test({
        name: 'dateTimeMin',
        message: message || string.dateTimeMin,
        params: { min: paramTargetDateTime },
        test(value) {
          if (value) {
            return moment(
              paramTargetDateTime,
              paramDateTimeFormat
            ).isSameOrBefore(moment(value, paramDateTimeFormat));
          }
          return true;
        },
      });
    },
  };
}

/**
 * YUP 전용: dateTime이 targetDateTime 보다 이전 날짜 인지 유효성 체크
 * @memberof yupMethods
 * @method dateTimeMax
 * @param {string} [targetDateTime=오늘날짜(YYYY-MM-DD)] - 비교 대상 날짜시간(ex: 2023-04-12)
 * @param {string} [dateTimeFormatArg=DATE_TIME_FORMAT_TYPE.DASH_YYYYMMDD] - dateTimeFormat(DATE_TIME_FORMAT_TYPE 상수 이용)
 * @param {string} [message] - 에러 메세지
 */
function dateTimeMax() {
  return {
    schemaType: 'string',
    name: 'dateTimeMax',
    func(targetDateTime, dateTimeFormatArg, message) {
      const paramDateTimeFormat =
        dateTimeFormatArg || DATE_TIME_FORMAT_TYPE.DASH_YYYYMMDD;
      const paramTargetDateTime =
        targetDateTime || moment().format(paramDateTimeFormat);
      return this.test({
        name: 'dateTimeMax',
        message: message || string.dateTimeMax,
        params: { max: paramTargetDateTime },
        test(value) {
          if (value) {
            return moment(value, paramDateTimeFormat).isSameOrBefore(
              moment(paramTargetDateTime, paramDateTimeFormat)
            );
          }

          return true;
        },
      });
    },
  };
}

/**
 * YUP 전용: color hex 유효성 체크
 * @memberof yupMethods
 * @method colorHex
 * @param {string} [message] - 에러 메세지
 */
function colorHex() {
  return {
    schemaType: 'string',
    name: 'colorHex',
    func(message) {
      return this.test({
        name: 'colorHex',
        message: message || string.colorHex,
        test(value) {
          if (value) {
            return /^#[0-9a-fA-F]{6}$/.test(value);
          }

          return true;
        },
      });
    },
  };
}

/**
 * YUP 전용: phone 유효성 체크
 * @memberof yupMethods
 * @method phone
 * @param {string} [message] - 에러 메세지
 */
function phone() {
  return {
    schemaType: 'string',
    name: 'phone',
    func(message) {
      return this.test({
        name: 'phone',
        message: message || string.phone,
        test(value) {
          if (value && value.startsWith('0')) {
            return /^(0(\d{1,2}))-(\d{3,4})-(\d{4})$/.test(value);
          }
          return true;
        },
      });
    },
  };
}

/**
 * YUP 전용: 사업자 등록번호 유효성 체크
 * @memberof yupMethods
 * @method bizNum
 * @param {string} [message] - 에러 메세지
 */
function bizNum() {
  return {
    schemaType: 'string',
    name: 'bizNum',
    func(message) {
      return this.test({
        name: 'bizNum',
        message: message || string.bizNum,
        test(value) {
          if (value) {
            return /^\d{3}-\d{2}-\d{5}$/.test(value);
          }
          return true;
        },
      });
    },
  };
}

/**
 * YUP 전용: 종목 코드 유효성 체크
 * @memberof yupMethods
 * @method itemCode
 * @param {string} [message] - 에러 메세지
 */
function itemCode() {
  return {
    schemaType: 'string',
    name: 'itemCode',
    func(message) {
      return this.test({
        name: 'itemCode',
        message: message || string.itemCode,
        test(value) {
          if (value) {
            return /^A\d{6}$/.test(value);
          }

          return true;
        },
      });
    },
  };
}

/**
 * YUP 전용: itemsFunc에 값이 포함인지 여부 체크
 *
 * @memberof yupMethods
 * @method bizNum
 * @param {function} [itemsFunc = () => []] - array를 반환하는 함수
 * @param {String} [items[].text] - 에러 메세지로 표시할 key text
 * @param {any} [items[].key] - 포함으로 비교할 key 값
 * @param {string} [message] - 에러 메세지
 */
function oneOfByKeyText() {
  return {
    schemaType: 'array',
    name: 'oneOfByKeyText',
    func(itemsFunc, message) {
      return this.test({
        name: 'oneOfByKeyText',
        message: message || mixed.oneOf,
        params: {
          values: map(itemsFunc() || [], (item) => item.text).join(', '),
        },
        async test(value) {
          if (value && value.length > 0) {
            const keys = map(itemsFunc() || [], (item) => item.key);
            return every(value, (val) => keys.includes(val));
          }
          return true;
        },
      });
    },
  };
}

/**
 * YUP 전용: 공통 async test 메소드 제공
 *
 * @memberof yupMethods
 * @method asyncTest
 * @param {Function} [conditionFunc = () => false] - validateFunc을 실행 시킬 조건 함수
 * @param {Function} [validateFunc] - conditionFunc조건이 실행했을때 true이면 동작
 * @param {Function | String} [message=''] - message 함수 | 문자열
 * @param {Object} [params] - message로 넘길 파라미터
 */
function asyncTest() {
  const schemaTypes = [
    'mixed',
    'string',
    'number',
    'date',
    'boolean',
    'object',
    'array',
  ];
  return map(schemaTypes, (schemaType) => ({
    schemaType,
    name: 'asyncTest',
    func({
      conditionFunc = async () => false,
      validateFunc = async () => true,
      message = '',
      params = () => ({}),
    }) {
      if (!message) {
        throw new Error('[yupMethods.asyncTest] message는 필수값 입니다.');
      }

      return this.test({
        name: 'asyncTest',
        message,
        params: params(),
        async test(value) {
          if (await conditionFunc()) {
            const result = await validateFunc(value);
            return result;
          }
          return true;
        },
      });
    },
  }));
}

/**
 * YUP 전용: page url 유효성 체크
 * @memberof yupMethods
 * @method pageUrl
 * @param {string} [message] - 에러 메세지
 */
function pageUrl() {
  return {
    schemaType: 'string',
    name: 'pageUrl',
    func(message) {
      return this.test({
        name: 'pageUrl',
        message: message || string.pageUrl,
        test(value) {
          if (value) {
            return /(\/([a-zA-Z0-9])\w+)\b(?:[-a-zA-Z0-9()@:%_+.~#?&/=]*)$/.test(
              value
            );
          }

          return true;
        },
      });
    },
  };
}

/**
 * YUP 전용: email 유효성 체크
 * @memberof yupMethods
 * @method email
 * @param {string} [message] - 에러 메세지
 */
function customEmail() {
  return {
    schemaType: 'string',
    name: 'customEmail',
    func(message) {
      return this.test({
        name: 'customEmail',
        message: message || string.email,
        test(value) {
          if (value) {
            return /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(value);
          }
          return true;
        },
      });
    },
  };
}

export default [
  cardNum(),
  numeric(),
  integerComma(),
  floatComma(),
  decimalPoint(),
  numberDecimalPoint(),
  ip(),
  dateTimeFormat(),
  dateTimeMin(),
  dateTimeMax(),
  colorHex(),
  phone(),
  bizNum(),
  oneOfByKeyText(),
  itemCode(),
  asyncTest(),
  pageUrl(),
  customEmail(),
];
