import { defineStore } from 'pinia';
import { ref } from 'vue';
import { useScrollLock } from '@vueuse/core';

export const useSpinnerStore = defineStore('common/useSpinnerStore', () => {
  const isOpen = ref(false);
  const wrapper = document.querySelector('body');
  const isLocked = useScrollLock(wrapper);

  function open() {
    isOpen.value = true;
    isLocked.value = true;
  }

  function close() {
    isOpen.value = false;
    isLocked.value = false;
  }

  return {
    isOpen, open, close,
  };
});
