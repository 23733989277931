import { SESSION_STORAGE_KEY } from '@/constants';
import { useSessionStorage } from '@vueuse/core';
import { render } from 'vue';

/**
 * isNumeric -> true or false
 * @param {any} value
 * @returns boolean
 */
export function isNumeric(value) {
  if (value !== 0 && !value) return false;
  // eslint-disable-next-line no-restricted-globals
  if (typeof (value) === 'number' && !isNaN(value)) return true;
  if (typeof (value) !== 'string') return false;

  const number = String(value).trim();
  // eslint-disable-next-line no-restricted-globals
  return number.trim() !== '' && !isNaN(number);
}

/**
 * replaceAll 처리
 * util.replaceAll('testtest', 'e', ''); -> tsttst
 * util.replaceAll('te-st.te.st', '\\.', ''); -> te-sttest
 * @param {*} string
 * @param {*} regex
 * @param {*} replaceTo
 * @returns string
 */
//
export function replaceAll(string, regex, replaceTo) {
  return string.toString().replace(new RegExp(regex, 'g'), replaceTo);
}

/**
 * 숫자 콤마 처리
 * util.addNumberComma(123456789.1234) -> 123,456,789.1234
 * @param {*} value
 * @returns string
 */
export function addNumberComma(value) {
  if (!isNumeric(value)) {
    return value;
  }

  const tempValue = value.toString();
  let returnValue = tempValue.replace(/^0+/, ''); // 시작 부분 0 제거
  const regEx = /(^[+-]?\d+)(\d{3})/;

  while (regEx.test(returnValue)) {
    returnValue = returnValue.replace(regEx, '$1,$2');
  }

  return returnValue;
}

/**
 * 숫자 콤마 제거 처리
 * util.removeNumberComma('123,456,789.1234'); -> 123456789.1234
 * @param {*} value
 * @returns
 */
export function removeNumberComma(value) {
  const NUMBER_REGEX = /^[0-9.,]+$/;
  if (!NUMBER_REGEX.test(value)) return value;

  return value.replace(/(,)/g, '');
}

//
// cartesianProduct([1, 2], [10, 20], [100, 200, 300])
// => [
//   [1, 10, 100],
//   [1, 10, 200],
//   [1, 10, 300],
//   [2, 10, 100],
//   [2, 10, 200]
//   ...
// ]
/**
 * cartesian product
 * cartesianProduct([1, 2], [10, 20], [100, 200, 300])
 *  => [
 *    [1, 10, 100],
 *    [1, 10, 200],
 *    [1, 10, 300],
 *    [2, 10, 100],
 *    [2, 10, 200]
 *    ...
 *  ]
 * @param  {...any} all
 * @returns
 */
export function cartesianProduct(...all) {
  // 파라미터로 배열이 아닌 값이 들어오면 빈 배열 반환
  if (!all.every((v) => Array.isArray(v))) return [];

  function loop(t, a, ...more) {
    return a === undefined
      ? [t]
      : a.flatMap((x) => loop([...t, x], ...more));
  }
  return loop([], ...all);
}

/**
 * vue app 가상노드 생성
 *
 * @param {*} app
 * @param {*} vnode
 * @param {*} elementClassName
 * @returns
 */
export function generateVNode(app, vnode, elementClassName = '') {
  vnode.appContext = app._context;
  const container = document.createElement('div');
  if (elementClassName) {
    container.className = elementClassName;
  }
  render(vnode, container);
  if (container) {
    document.body.appendChild(container);
  }
  return vnode;
}

/**
 * sleep 함수
 * @param {*} time
 */
export async function sleep(time = 1000) {
  let timeout = null;
  await new Promise((resolve) => {
    timeout = setTimeout(resolve, time);
  });

  if (timeout) {
    clearTimeout(timeout);
    timeout = null;
  }
}

/**
 * 영어문자 체크
 * @param {*} text
 * @returns boolean
 */
export function isEnglishText(text) {
  if (text) {
    const english = /^[A-Za-z0-9]*$/;
    return english.test(text);
  }
  return false;
}

/**
 * passive 지원 체크
 * @returns boolean
 */
export function checkSupportsPassive() {
  let supportsPassive = false;
  try {
    const opts = Object.defineProperty({}, 'passive', {
      // eslint-disable-next-line getter-return
      get() {
        supportsPassive = true;
      },
    });
    window.addEventListener('testPassive', null, opts);
    window.removeEventListener('testPassive', null, opts);
  } catch (e) {
    console.error('checkSupportsPassive(브라우저가 지원하지 않습니다.)', e);
  }
  return supportsPassive;
}

/**
 * 정규식을 이용하여 반복적으로 문자열 치환
 * @param {*} value
 * @param {*} regEx
 * @param {*} replaceTo
 * @param {*} lengthLimit
 * @returns
 */
export function replaceByRegexRepeatedly(value, regEx, replaceTo = '$1-$2', lengthLimit = Infinity) {
  let result = value;
  while (regEx.test(result)) {
    if (result.length >= lengthLimit) {
      break;
    }
    result = result.replace(regEx, replaceTo);
  }
  return result;
}

/**
 * mobile 기기 체크
 * @returns
 */
export function isMobile() {
  const regex = /Mobi|Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
  return regex.test(navigator.userAgent);
}

/**
 * os check in Mobile
 * @returns
 */
export const getMobileOS = () => {
  const ua = navigator.userAgent;

  if (/android/i.test(ua)) {
    return 'Android';
  } if (
    /iPad|iPhone|iPod/.test(ua)
    || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)
  ) {
    return 'iOS';
  }

  return 'Other';
};

/**
 * 4자리 마다 - 추가
 * @param {*} [num, string]
 * @returns
 */
export function formatNumber(num) {
  return num.toString().replace(/\B(?=(\d{4})+(?!\d))/g, '-');
}

// 객체가 비어있는지 확인
function isEmptyObject(obj) {
  return obj && typeof obj === 'object' && Object.keys(obj).length === 0;
}

// 배열이 비어있는지 확인
function isEmptyArray(arr) {
  return Array.isArray(arr) && arr.length === 0;
}

export function isEmpty(value) {
  if (Array.isArray(value)) {
    // 배열이 비어있는지 체크
    return isEmptyArray(value);
  } if (value && typeof value === 'object') {
    // 객체가 비어있는지 체크
    return isEmptyObject(value);
  }
  // 문자열, 숫자 등 다른 타입은 빈 값으로 처리
  return value == null || value === '';
}

export function closeWebview() {
  console.log(useSessionStorage(SESSION_STORAGE_KEY.MTS_PROVIDER).value);
  if (useSessionStorage(SESSION_STORAGE_KEY.MTS_PROVIDER).value === 'SH') {
    const query = {
      formname: '',
      eventname: 'goMyPage',
      param: {
        screenNo: '0600',
      },
    };
    const encodedQuery = encodeURIComponent(JSON.stringify(query));
    const url = `ns://controlform.shinhansec.com?trigger=${encodedQuery}`;
    console.log(url);
    window.location.href = url;
  }
}
