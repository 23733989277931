import { generateVNode } from '@/utils';
import { createVNode, ref, render } from 'vue';
import MyCoupon from './components/MyCoupon.vue';

const MyCouponPlugin = {
  install(app) {
    const couponInstanceRef = ref(null);
    let couponVNode;

    function openWithProps(props) {
      // 동적 props로 VNode 생성
      couponVNode = createVNode(MyCoupon, props);

      // props 때문에 계속 render 되어야 하는 부분으로 인한 container 복사 방어코드
      const container = document.querySelector('.teleport-my-coupon');
      if (container) {
        render(null, container);
        document.body.removeChild(container);
      }

      generateVNode(app, couponVNode, 'teleport-my-coupon');

      // console.log(couponVNode.component);
      couponInstanceRef.value = couponVNode.component?.exposed;
      couponInstanceRef.value.open();
    }

    app.provide('myCoupon', {
      instance: couponInstanceRef,
      open: openWithProps,
    });
    app.config.globalProperties.$myCoupon = {
      instance: couponInstanceRef,
      open: openWithProps,
    };
  },
};

export default MyCouponPlugin;
