/**
 * @desc Button text size constants.
 * @memberof constants
 * @readonly
 * @const {Object} BTN_TEXT_SIZE
 * @property {String} MD - Medium text size.
 * @property {String} SM - Small text size.
 */
export const BTN_TEXT_SIZE = Object.freeze({
  MD: 'md',
  SM: 'sm',
});

/**
 * @description BUTTON SOLID TYPE constants.
 * @memberof constants
 * @readonly
 * @const {Object} BTN_LARGE_TYPE
 * @property {String} PRIMARY - Primary button type.
 * @property {String} SECONDARY - Secondary button type.
 * @property {String} TERTIARY - Tertiary button type.
 * @property {String} DEFAULT - Default button type.
 * @property {String} WARNING - Warning button type.
 */
export const BTN_LARGE_TYPE = Object.freeze({
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
  TERTIARY: 'tertiary',
  DEFAULT: 'default',
  WARNING: 'warning',
});

export const BTN_SMALL_TYPE = Object.freeze({
  OUTLINE: 'OUTLINE',
  SOLID: 'SOLID',
});

/**
 * @description BUTTON SIZE constants.
 * @memberof constants
 * @readonly
 * @const {Object} BS_SIZE
 * @property {String} LARGE - Large button size.
 * @property {String} SMALL - Small button size.
 */
export const BS_SIZE = Object.freeze({
  LARGE: 'lg',
  SMALL: 'sm',
});

/**
 * @description SERIAL_TYPE constants.
 */
export const SERIAL_TYPE = Object.freeze({
  BARCODE: 'BARCODE',
  SERIAL: 'SERIAL',
});

/**
 * @description {*} couponStatus full
 */
export const COUPON_STATUS_FLOW = Object.freeze({
  ISSUE_POSSIBLE: 'ISSUE_POSSIBLE', // 발급
  COUPON_STATUS_NOT_USE: 'COUPON_STATUS_NOT_USE', // 사용(미사용)
  COUPON_STATUS_USE: 'COUPON_STATUS_USE', // 사용완료
  EXPIRE_PERIOD: 'EXPIRE_PERIOD', // 사용기간 만료
  DOWNLOAD_PERIOD_EXPIRE: 'DOWNLOAD_PERIOD_EXPIRE', // 발급기간 만료
  USE_IMPOSSIBLE: 'USE_IMPOSSIBLE', // 사용불가
});

/**
 * @description {*} couponStatus short is same couponState of api
 */
export const COUPON_STATUS_SHORT = Object.freeze({
  IP: 'ISSUE_POSSIBLE',
  CNU: 'COUPON_STATUS_NOT_USE',
  CSU: 'COUPON_STATUS_USE',
  EP: 'EXPIRE_PERIOD',
  DPE: 'DOWNLOAD_PERIOD_EXPIRE',
  UI: 'USE_IMPOSSIBLE',
});

/**
 * @description 쿠폰 발급 후 상태 for disabled of button
 */
export const AFTER_ISSUE_STATE = Object.freeze({
  SUCCESS: 'SUCCESS',
  FAIL: 'FAIL',
});

// CDN 주소
export const CDN_URL = 'http://rxbgmqmamrks27229088.gcdn.ntruss.com';
