import { defineStore } from 'pinia';
import { ref } from 'vue';

export const useToastStore = defineStore('common/toastStore', () => {
  const stackToast = ref([]);
  const id = ref(0);
  const MAX = 1; // 최대 보여줄 갯수

  const getId = () => {
    id.value = id.value > 100 ? 0 : id.value + 1; // +1로 수정
    return id.value;
  };

  function open({
    message = '',
    showCloseBtn = false,
    closeTime = 4000,
    actionText = '',
    whiteMode = false,
    action = () => {},
  }) {
    if (stackToast.value.length >= MAX) stackToast.value.shift(); // >= 로 수정하여 최대 수 초과시 제거
    const toastId = getId();
    stackToast.value.push({
      uId: toastId,
      message,
      showCloseBtn,
      closeTime,
      whiteMode,
      action,
      actionText,
    });
  }

  const close = (toastId) => { // 매개변수에서 객체 구조 분해 할당 제거
    stackToast.value = stackToast.value.filter((value) => value.uId !== toastId);
  };

  return {
    open,
    close,
    stackToast,
  };
});
