<script setup>
/**
 * 대형 버튼 컴포넌트
 *
 * @emits click 버튼 클릭 이벤트
 */
import { BTN_LARGE_TYPE } from '@/constants';

const props = defineProps({
  /**
   * 버튼의 타입 (기본값: BTN_LARGE_TYPE.PRIMARY)
   * 유효한 값: BTN_LARGE_TYPE.PRIMARY,
   * BTN_LARGE_TYPE.SECONDARY,
   * BTN_LARGE_TYPE.TERTIARY,
   * BTN_LARGE_TYPE.DEFAULT,
   * BTN_LARGE_TYPE.WARNING
   */
  type: {
    type: String,
    default: BTN_LARGE_TYPE.PRIMARY,
    validator(value) {
      return [BTN_LARGE_TYPE.PRIMARY,
        BTN_LARGE_TYPE.SECONDARY,
        BTN_LARGE_TYPE.TERTIARY,
        BTN_LARGE_TYPE.DEFAULT,
        BTN_LARGE_TYPE.WARNING].includes(value);
    },
  },
  /**
   * 버튼의 텍스트
   */
  text: {
    type: String,
    default: '',
  },
  /**
   * 로딩 중인 상태 여부
   */
  pending: {
    type: Boolean,
    default: false,
  },
  /**
   * 버튼 비활성화 여부
   */
  disabled: {
    type: Boolean,
    default: false,
  },
  /**
   * 버튼 클릭 시 실행할 함수
   */
  action: {
    type: Function,
    default: () => {},
  },
});

const emits = defineEmits(['click']);

/**
 * 버튼 클릭 이벤트 핸들러
 */
const onClick = () => {
  if (props.action()) {
    props.action();
  }
  emits('click');
};
</script>

<template>
  <button
    type="button"
    class="b-btn-large"
    :class="[type, { pending: pending }]"
    @click="onClick"
    :disabled="disabled"
  >
    <span v-if="!pending">{{ text }}</span>
  </button>
</template>

<script>
/**
 * @example
 * <BtnLarge
 *  :type=BTN_LARGE_TYPE.PRIMARY
 *  text="text"
 *  :pending="isLoading"
 *  :disabled="disabled"
 *  :action = 'handleSubmit'
 *  @click="onClick"/>
*/
</script>
