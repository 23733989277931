import { generateVNode } from '@/utils';
import { createVNode } from 'vue';
import Spinner from '@/components/common/Spinner.vue';
import { useSpinnerStore } from '@/stores/common/spinnerStore';

const SpinnerPlugin = {
  install(app) {
    generateVNode(app, createVNode(Spinner, {}), 'teleport-spinner');

    const spinnerStore = useSpinnerStore();

    const target = {
      open() {
        return spinnerStore.open();
      },
      close() {
        return spinnerStore.close();
      },
    };

    app.provide('spinner', target);
    app.config.globalProperties.$spinner = target;
  },
};

export default SpinnerPlugin;
