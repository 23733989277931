import * as yup from 'yup';
import methods from './methods';
import yupLocaleKo from './localeKo';
import { isArray, forEach } from 'lodash-es'; // tree shaking 을 위해 반드시 필요한 함수만 호출

// yup 기본 locale 설정
yup.setLocale(yupLocaleKo);

// yup custom method 추가
methods.forEach((method) => {
  if (isArray(method)) {
    forEach(method, (m) => {
      yup.addMethod(yup[m.schemaType], m.name, m.func);
    });
  } else {
    yup.addMethod(yup[method.schemaType], method.name, method.func);
  }
});

export const localeKo = yupLocaleKo;

export default yup;
