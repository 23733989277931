<script setup>
import { ref, watch } from 'vue';
import { useModalStore } from '@/stores/common/modalStore';
import BtnLarge from '@/components/button/BtnLarge.vue';
import Dimmed from '@/components/common/Dimmed.vue';

const title = ref('');
const message = ref('');
const buttons = ref([]);
const isOpen = ref(false);
const modalStore = useModalStore();

/**
 * buttons = [
 * {
 *  type
 *  text
 *  action () = {}
 * }]
 */

const open = () => {
  isOpen.value = true;
  modalStore.open();
};

const close = () => {
  isOpen.value = false;
  modalStore.close();
};

const onClickDimmed = () => {
  close();
};

watch(() => modalStore.isOpen, (n) => {
  if (!n && isOpen.value) {
    isOpen.value = false;
  }
});

defineExpose({
  title,
  message,
  isOpen,
  buttons,
  open,
  close,
});

</script>
<template>
  <!-- 전역은 teleport 사용 금지 -->
  <Dimmed :open="isOpen" @click:dimmed="onClickDimmed"/>
  <transition name="fade">
    <div class="b-confirm" v-if="isOpen">
      <p class="title" v-if="title">{{ title }}</p>
      <p
        class="desc"
        v-if="message"
        v-dompurify-html="message"
      ></p>
      <div class="btn-wrap" v-if="buttons.length > 0">
        <BtnLarge
          v-for="btn, index in buttons"
          :key='`confirm-btn-${index}`'
          :type="btn.type"
          :text="btn.text"
          :pending="btn.pending"
          :disabled="btn.disabled"
          :action="btn.action"
        />
      </div>
    </div>
  </transition>

</template>

<script>
/**
 * Opens the confirmation dialog with the specified options.
 *
 * @param {Object} options - The options for configuring the confirmation dialog.
 * @param {string} options.title - The title text displayed in the dialog.
 * @param {string} options.message - The message text displayed in the dialog.
 * @param {Object[]} options.buttons - The array of button objects displayed in the dialog.
 * @param {string} options.buttons[].type - The type of the button (e.g., 'default', 'primary').
 * @param {string} options.buttons[].text - The text displayed on the button.
 * @param {boolean} options.buttons[].pending - Whether the button shows a loading state.
 * @param {boolean} options.buttons[].disabled - Whether the button is disabled.
 * @param {Function} options.buttons[].action - The function called when the button is clicked.
 *
 * @example
 * confirm.open({
 *   title: '안녕하세요',
 *   message: '내용입니다.',
 *   buttons: [
 *     {
 *       type: BTN_LARGE_TYPE.DEFAULT,
 *       text: '취소',
 *       pending: false,
 *       disabled: false,
 *       action: () => {
 *         console.log('취소액션');
 *         confirm.close();
 *       },
 *     },
 *     {
 *       type: BTN_LARGE_TYPE.PRIMARY,
 *       text: '결과 내용',
 *       pending: false,
 *       disabled: false,
 *       action: () => {
 *         console.log('결과 내용');
 *       },
 *     },
 *   ],
 * });
 */

</script>
