import { ERROR_TYPE, ROUTER_PATH, SESSION_STORAGE_KEY } from '@/constants';
import { useSessionStorage } from '@vueuse/core';
import { useAppInfoStore } from '@/stores/common/appInfoStore';
import { useModalStore } from '@/stores/common/modalStore';
import { pageview } from 'vue-gtag';

export const beforeEach = async (to, from, next) => {
  const appInfoStore = useAppInfoStore();
  const modalStore = useModalStore();

  if (to.query.serialKey || to.query.securitiesCode) {
    await appInfoStore.setApiKey(to.query.serialKey, to.query.securitiesCode);
  }

  /*
  //srKey, mts 가 없으면 error 페이지 랜딩
  if (!appInfoStore.getApiKey().srKey || !appInfoStore.getApiKey().mts) {
    if (to.path !== ROUTER_PATH.ERROR || to.query.state !== ERROR_TYPE.ACCESS_DENIED) {
      next({ path: ROUTER_PATH.ERROR, query: { state: ERROR_TYPE.ACCESS_DENIED } });
      return;
    }
  }
  */

  // set modal close when modal open and click back btn
  if (modalStore.isOpen) {
    modalStore.close();
    next(false);
    return;
  }

  // about GA
  pageview({
    page_path: to.fullPath,
    mts: appInfoStore.getApiKey().mts, // 커스텀 매게 변수
  });

  next();
};
