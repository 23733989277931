<script setup>
import { useToastStore } from '@/stores/common/toastStore';
import { storeToRefs } from 'pinia';
import Toast from './Toast.vue';

const toastStore = useToastStore();
const { stackToast } = storeToRefs(toastStore);

const onClickClose = (uId) => {
  stackToast.value = stackToast.value.filter((value) => value.uId !== uId);
};

const onClickAction = () => {

};

</script>
<template>
  <TransitionGroup name="slide">
    <Toast
      v-for="toast in stackToast"
      :key="toast.uId"
      :uId="toast.uId"
      :message="toast.message"
      :action="toast.action"
      :actionText="toast.actionText"
      :closeTime="toast.closeTime"
      :whiteMode="toast.whiteMode"
      :showCloseBtn="toast.showCloseBtn"
      @click:close="onClickClose"
      @click:action="onClickAction"
    />
  </TransitionGroup>
</template>
