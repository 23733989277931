import env from '@/config/env';
import { SESSION_STORAGE_KEY } from '@/constants';
import { useSessionStorage } from '@vueuse/core';
import { defineStore } from 'pinia';
import { ref } from 'vue';

export const useAppInfoStore = defineStore('common/appInfoStore', () => {
  const srKey = ref('');
  const mtsProvider = ref('');

  /**  test code
   *
   * This is URL to move the 'Use Benefit' page directly to the MTS
   * http://localhost:8090/dub?serialKey=sh123456abcd12&securitiesCode=SH
  */
  // 방어 코드
  if (env.app.mode !== 'production') {
    setSessionApiKey('sh47qv065bhqaq', 'SH');
  }

  const setApiKey = (key, mts) => {
    // srKey 있으면 return
    if (getApiKey().srKey && getApiKey().mts) {
      return;
    }

    srKey.value = key;
    mtsProvider.value = mts;
    setSessionApiKey(srKey.value, mtsProvider.value);
  };

  function getApiKey() {
    return {
      srKey: useSessionStorage(SESSION_STORAGE_KEY.API_KEY).value,
      mts: useSessionStorage(SESSION_STORAGE_KEY.MTS_PROVIDER).value,
    };
  }

  function setSessionApiKey(key, mts) {
    if (!key || !mts) {
      console.warn('srKey, mts 정보가 없습니다.');
      return;
    }
    console.log(`srKey : ${key}, mts: ${mts}`);
    useSessionStorage(SESSION_STORAGE_KEY.API_KEY, key);
    useSessionStorage(SESSION_STORAGE_KEY.MTS_PROVIDER, mts);
  }

  return {
    setApiKey,
    getApiKey,
    setSessionApiKey,
  };
});
