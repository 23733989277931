import { josa } from 'josa';

function displayType(type) {
  switch (type) {
  case 'string':
    return '문자열';
  case 'number':
    return '숫자';
  case 'boolean':
    return '불리언';
  case 'date':
    return '날짜';
  case 'object':
    return '객체';
  case 'array':
    return '배열';
  default:
    return type;
  }
}

export const mixed = {
  default: () => '입력하신 데이터를 다시 확인해 주세요',
  required: () => '필수 입력 항목입니다',
  oneOf: ({ values }) => josa(`다음 중 하나여야 합니다: (${values})`),
  notOneOf: ({ values }) => josa(`다음 중 하나가 아니어야 합니다: (${values})`),
  notType: ({ type }) => josa(`${displayType(type)} 유형이어야 합니다`),
  defined: ({ path }) => josa(`${path}#{를} 정의해야합니다`),
};

export const string = {
  length: ({ length }) => josa(`${length}자리에 맞춰 입력해 주세요`),
  min: ({ min }) => josa(`${min}자 이상 입력해 주세요`),
  max: ({ max }) => josa(`${max}자까지 입력할 수 있습니다`),
  matches: ({ regex }) => josa(`형식에 맞춰 입력해 주세요: "${regex}"`),
  email: () => '올바른 이메일 형식이 아닙니다',
  url: () => '올바른 URL 형식이 아닙니다',
  pageUrl: () => '형식에 맞춰 입력해 주세요: "/menu"',
  uuid: () => '올바른 UUID 형식이 아닙니다',
  trim: () => '앞뒤 공백을 삭제해주세요',
  ip: () => '올바른 IP 형식이 아닙니다',
  lowercase: () => '소문자만 입력할 수 있습니다',
  uppercase: () => '대문자만 입력할 수 있습니다',
  cardNum: () => '유효한 카드 번호가 아닙니다',
  numeric: () => '숫자만 입력해 주세요',
  integerComma: () => '정수만 입력할 수 있습니다',
  floatComma: () => '소수점을 포함하여 입력해 주세요',
  decimalPoint: ({ decimalPoint }) => josa(`소수점 ${decimalPoint}자리까지 입력할 수 있습니다`),
  dateTimeFormat: ({ dateTimeFormat: dateTimeFormatParam }) => josa(`${dateTimeFormatParam} 형식이 아닙니다`),
  dateTimeMin: ({ min }) => josa(`${min}의 이후 여야 합니다`),
  dateTimeMax: ({ max }) => josa(`${max}의 이전이어야 합니다`),
  colorHex: () => '올바른 Hex code 형식이 아닙니다',
  phone: () => '올바른 전화번호 형식이 아닙니다',
  bizNum: () => '올바른 사업자등록번호 형식이 아닙니다',
  itemCode: () => '올바른 종목코드 형식이 아닙니다',
  duplicate: ({ path }) => josa(`동일한 ${path}#{가} 있습니다`),
  capsLock: () => 'CapsLock이 켜져 있습니다',
  checkSetting: () => '설정 조건을 확인해 주세요',
  authNum: () => '인증 번호가 일치하지 않습니다',
  timeoutInput: () => '입력 시간을 초과했습니다',
};

export const number = {
  length: ({ length }) => josa(`${length}자리에 맞춰 입력해 주세요`),
  min: ({ min }) => josa(`${min} 이상 입력해 주세요`),
  max: ({ max }) => josa(`${max}까지 입력할 수 있습니다`),
  lessThan: ({ less }) => josa(`${less}보다 작아야 합니다`),
  moreThan: ({ more }) => josa(`${more}보다 커야 합니다`),
  positive: () => '양수로 입력해 주세요',
  negative: () => '음수로 입력해 주세요',
  integer: () => '정수로 입력해 주세요',
  decimalPoint: ({ decimalPoint }) => josa(`소수점 ${decimalPoint}자리까지 입력할 수 있습니다`),
};

export const date = {
  min: ({ min }) => josa(`${min} 이후여야 합니다`),
  max: ({ max }) => josa(`${max} 이전이어야 합니다`),
};

export const boolean = {
  isValue: ({ value }) => josa(`${value} 값 이어야 입력해 주세요`),
};

export const object = {
  noUnknown: () => josa('정의되지 않은 키가 있습니다'),
};

export const array = {
  min: ({ min }) => josa(`${min}개 이상 선택해주세요`),
  max: ({ max }) => josa(`${max}개까지 선택할 수 있습니다`),
  length: ({ length }) => josa(`총 ${length}개 선택해야 합니다`),
};

const yupLocaleKo = {
  mixed,
  string,
  number,
  date,
  object,
  array,
  boolean,
};

export default yupLocaleKo;
