import router from '@/router';
import VueDOMPurifyHTML from 'vue-dompurify-html';
import VueJsBarcode from 'vue-jsbarcode';
import VueGtag from 'vue-gtag';
import yup from './yup';
import axios from './axios';
import moment from './moment';
import pinia from '../stores';
import toast from './toast';
import myCoupon from './myCoupon';
import confirm from './confirm';
import spinner from './spinner';

export default function registerPlugins(app) {
  app
    .use(pinia)
    .use(router)
    .use(VueDOMPurifyHTML)
    .use(toast)
    .use(myCoupon)
    .use(confirm)
    .use(spinner)
    .use(VueJsBarcode)
    .use(VueGtag, {
      config: { id: 'G-B1T4D4N2VK' },
    }, router);
}

export {
  yup, moment, pinia, router, axios,
};
