/**
 * @namespace ROUTER_PATH
 * @desc 애플리케이션에서 사용되는 경로 상수 정의
 * @memberof constants
 * @readonly
 * @const {Object} ROUTER_PATH
 * @property {string} SPLASH - 로딩 화면 ("/loading")
 * @property {string} HOME - 홈 화면 ("/")
 * @property {string} MY_BENEFITS - 내 주주 혜택 화면 ("/my")
 * @property {string} PREVIOUS_BENEFITS - 지난 혜택 화면 ("/pb")
 * @property {string} USE_BENEFITS - 혜택 사용 화면 ("/ub")
 * @property {string} BRAND_HOME - 브랜드관 홈 화면 ("/br/bh/:id")
 * @property {string} BRAND_DETAIL - 브랜드관 상세 화면 ("/br/bd/:id")
 * @property {string} GROUP_HOME - 그룹관 홈 화면 ("/gr/gh/:id")
 * @property {string} GROUP_LIST - 그룹관 목록 화면 ("/gr/gl/:id")
 * @property {string} NOTICE_LIST - 공지사항 목록 화면 ("/no/nl")
 * @property {string} NOTICE_DETAIL - 공지사항 상세 화면 ("/no/nd/:id")
 * @property {string} PRIVATE_POLICY - 개인정보 처리방침 화면 ("/pp/:id")
 * @property {string} TERMS_LIST - 약관 목록 화면 ("/te/tl")
 * @property {string} TERMS_DETAIL - 약관 상세 화면 ("/te/td/:id")
 * @property {string} FAQ - 자주 묻는 질문 화면 ("/fa")
 * @property {string} ERROR - 에러 페이지 ("/er?state")
 * @property {string} KAKAO - 카카오 페이지 ("/kakao") 카카오 알림톡 랜딩 페이지(추후 별도로 빠질 페이지)
 * @property {string} AGREE_TERMS - 약관 동의 화면 ("/at") 최초 1회 실행
 */
export const ROUTER_PATH = Object.freeze({
  SPLASH: '/loading',
  HOME: '/',
  MY_BENEFITS: '/mb',
  PREVIOUS_BENEFITS: '/pb',
  USE_BENEFITS: '/ub/:id', // 미사용
  USED_BENEFITS: '/udb/:id', // 사용
  DIRECT_USE_BENEFIT: '/dub', // 다이렉트 미사용 쿠폰
  BRAND_HOME: '/br/bh/:id',
  BRAND_DETAIL: '/br/bd/:id',
  GROUP_HOME: '/gr/gh/:id',
  GROUP_LIST: '/gr/gl/:id',
  NOTICE_LIST: '/no/nl',
  NOTICE_DETAIL: '/no/nd/:id',
  PRIVATE_POLICY: '/pp/:id',
  TERMS_LIST: '/te/tl',
  TERMS_DETAIL: '/te/td/:id',
  FAQ: '/fa',
  ERROR: '/er',
  KAKAO: '/kakao',
  AGREE_TERMS: '/at',
  AUTH: '/auth',
  AUTH_RESULT: '/nice/auth/result'

});

export const ROUTER_NAME = Object.freeze({
  SPLASH: 'SPLASH',
  HOME: 'HOME',
  MY_BENEFITS: 'MY_BENEFITS',
  PREVIOUS_BENEFITS: 'PREVIOUS_BENEFITS',
  USE_BENEFITS: 'USE_BENEFITS',
  USED_BENEFITS: 'USED_BENEFITS',
  DIRECT_USE_BENEFIT: 'DIRECT_USE_BENEFIT',
  BRAND_HOME: 'BRAND_HOME',
  BRAND_DETAIL: 'BRAND_DETAIL',
  GROUP_HOME: 'GROUP_HOME',
  GROUP_LIST: 'GROUP_LIST',
  NOTICE_LIST: 'NOTICE_LIST',
  NOTICE_DETAIL: 'NOTICE_DETAIL',
  PRIVATE_POLICY: 'PRIVATE_POLICY',
  TERMS_LIST: 'TERMS_LIST',
  TERMS_DETAIL: 'TERMS_DETAIL',
  FAQ: 'FAQ',
  ERROR: 'ERROR',
  KAKAO: 'KAKAO',
  AGREE_TERMS: '/AGREE_TERMS',
  AUTH: '/AUTH',
  AUTH_RESULT: '/AUTH_RESULT'
});
